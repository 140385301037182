import SmoothScroll from "smooth-scroll";
import AOS from "aos";

var pageHeight = window.innerHeight;

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

// loading animation
function loadingAnimation() {
  const logo = document.getElementById("logo");
  const opening = document.getElementById("opening");

  if (logo != null) {
    window.setTimeout(() => {
      logo.classList.add("hidden");

      logo.addEventListener("transitionend", () => {
        opening.style.animation = "topToBottom 500ms forwards";
      });
    }, 1000);
  }
}

function faqAccordion() {
  // faqに関する要素を取得
  const faq = document.getElementById("faq");
  const faqTitles = document.querySelectorAll(".c-faq__questions__title");
  const faqAnswers = document.querySelectorAll(".c-faq__questions__answer");
  const faqAnswersContent = document.querySelectorAll(
    ".c-faq__questions__answer__content"
  );

  // DOMにfaqセクションが存在する場合のみ処理を行う
  if (faq) {
    faqTitles.forEach((element, index) => {
      element.addEventListener("click", () => {
        // コンテンツのheightを取得
        const contentHeight = faqAnswersContent[index].scrollHeight;

        element.classList.toggle("active");

        // c-faq__questions__titleにactiveを含む時に高さをセットする
        if (element.classList.contains("active")) {
          faqAnswers[index].style.height = `${contentHeight}px`;
        } else {
          faqAnswers[index].style.height = "0";
        }
      });
    });
  } else {
    return;
  }
}

// モバイルメニューをクリックした際の処理
const jsMenuActive = document.querySelectorAll(".js-menu-active");
const mobileMenu = document.querySelector(".c-mobile-menu");
const mobileBackground = document.querySelector(".c-mobile-menu__background");
const mobileContent = document.querySelector(".c-mobile-menu__content");
const mobileIcon = document.querySelector(".c-mobile-menu__icon");

jsMenuActive.forEach(function (element) {
  element.addEventListener("click", () => {
    mobileMenu.classList.toggle("active-menu");
    mobileBackground.classList.toggle("active-background");
    mobileContent.classList.toggle("active-content");
    mobileIcon.classList.toggle("active-icon");
  });
});

function reveal() {
  let scroll = this.scrollY;
  const mobileIcon = document.getElementById("menu-icon");

  if (mobileIcon != null) {
    if (scroll >= pageHeight) {
      mobileIcon.style.transform = "translateX(0)";
    } else {
      mobileIcon.style.transform = "translateX(100%)";
    }
  }

  var reveals = document.querySelectorAll(".section-heading");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 0;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("ani-snake");
    } else {
      reveals[i].classList.remove("ani-snake");
    }
  }
}

function diagnosisAccordion() {
  const cleaningHeading = document.querySelectorAll(".cleaning__heading");
  const cleaningContent = document.querySelectorAll(".cleaning__content");

  if (cleaningHeading.length) {
    cleaningHeading.forEach((element, index) => {
      element.addEventListener("click", () => {
        // 展開したいコンテンツの高さを取得
        const contentHeight = cleaningContent[index].scrollHeight;

        // cleaningHeadingにactiveを追加
        element.classList.toggle("active");

        if (element.classList.contains("active")) {
          // コンテンツの展開
          cleaningContent[index].style.height = `${contentHeight}px`;
        } else {
          cleaningContent[index].style.height = 0;
        }
      });
    });
  } else {
  }
}

faqAccordion();

diagnosisAccordion();

window.addEventListener("scroll", reveal);

window.onload = () => {
  loadingAnimation();

  setTimeout(() => {
    var headings = document.querySelectorAll(".section-heading__top");
    for (var i = 0; i < headings.length; i++) {
      headings[i].classList.add("ani-snake");
    }
  }, 2500);
};

const topToBottom = document.getElementById("opening");
let fadeUp = document.querySelectorAll(".animation-fade-up");
let textWave = document.querySelectorAll(".animation-text-wave");
let zoomIn = document.querySelectorAll(".animation-zoom-in");

topToBottom.addEventListener("animationend", () => {
  fadeUp.forEach((element) => {
    element.classList.add("active");
  });

  textWave.forEach((element) => {
    element.classList.add("ani-snake");
  });

  zoomIn.forEach((element) => {
    element.classList.add("active");
  });
});
